/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Paper, Typography, Grid, Button, Modal } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { authGet, getBackendPath, convertS3UrlToHttps } from '../utilities';

const ReviewDetail = () => {
  const { reviewId } = useParams();
  const [review, setReview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchReview = async () => {
      try {
        const data = await authGet(`/api/reviews/${reviewId}`);
        console.log(data);
        setReview(data);
      } catch (error) {
        console.error('Error fetching review:', error);
      }
    };

    fetchReview();
  }, [reviewId]);

  const handleImageSelect = (imageId) => {
    setSelectedImage(imageId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(getBackendPath(`/api/reviews/${reviewId}`), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ image_id: selectedImage }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      alert('Image approved successfully');
    } catch (error) {
      console.error('Error approving image:', error);
    }
  };

  if (!review) {
    return <div>Loading...</div>;
  }

  return (
    <Container sx={{ marginTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h5" gutterBottom>
          {`${review.pet_name} - ${review.art_concept_name} - ${review.user_name}`}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Created: {new Date(review.created_at).toLocaleString()}
        </Typography>

        <Carousel className="carousel" sx={{ marginBottom: 0 }} width="500px">
          {review.reference_photos.map((photo) => (
            <div key={photo.id}>
              <img src={photo.original_photo} alt={`Reference for ${review.pet_name}`} />
            </div>
          ))}
        </Carousel>
        <Typography variant="subtitle1" gutterBottom>
          Prompt: {review.art_concept_prompt}
        </Typography>

        <Grid container spacing={2} sx={{ marginBottom: 4 }}>
          {review.generated_images.map((image) => (
            <Grid item xs={6} sm={4} md={3} key={image.id} sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src={image.thumbnail_url}
                alt="Generated"
                onClick={() => handleImageSelect(image.id)}
                sx={{
                  cursor: 'pointer',
                  width: '100%',
                  height: 'auto',
                  border: selectedImage === image.id ? '2px solid green' : 'none',
                }}
              />
            </Grid>
          ))}
        </Grid>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedImage}
          sx={{ marginTop: 2 }}
        >
          Approve Selected Image
        </Button>
      </Paper>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '90vh', // Limit the maximum height
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="image-modal-title" variant="h6" component="h2">
            Image Detail
          </Typography>
          {selectedImage && (
            <Box
              component="img"
              src={review.generated_images.find(image => image.id === selectedImage).thumbnail_url}
              alt="Detailed View"
              sx={{
                width: '100%',
                maxHeight: '70vh',
                objectFit: 'contain', // Ensure the image fits within the box
                mt: 2,
              }}
            />
          )}
          <Button onClick={handleCloseModal} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default ReviewDetail;
